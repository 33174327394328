<script setup>
import { ref, onMounted, watch, reactive } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from "primevue/useconfirm"
import axios from 'axios'
import { FilterMatchMode } from 'primevue/api'

/////////////////////
// Interface Props //
/////////////////////
const loader = ref(false)
const toast = useToast()
const confirm = useConfirm()

const filter_users = ref({global: {value: '', matchMode: FilterMatchMode.CONTAINS}})

const types = ref([
    {label: 'Student', value: 'student'},
    {label: 'Vertreter', value: 'rep'},
    {label: 'Firma', value: 'company'},
])

const users = ref([])

const new_user = ref({
    id: 0,
    first_name: '',
    last_name: '',
    email: '',
    mobile: '',
    type: 'student',
    courses: [],
    language: '',
    course_progress: 0,
})

const user = ref({})

const edit_user_slider = ref(false)
const new_user_dialog = ref(false)

onMounted(() => {
    get_users()
    get_courses()
})

const get_users = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/get-users')
    .then(response => {
        users.value = response.data
    })
}

const courses = ref([])
const get_courses = () => {
    axios.get(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/get-courses')
    .then(response => {
        courses.value = response.data
        create_course_selector(response.data)
    })
}
const course_selector = ref([])
const create_course_selector = (courses_res) => {
    course_selector.value = courses_res.map(course => ({label: course.name, value: course.id}))
}

const send_sms = (user) => {
    confirm.require({
        message: `Willst du ${user.first_name} ${user.last_name}, ${user.mobile} Zugang per SMS senden?`,
        header: 'Zugang senden',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja',
        acceptClass: 'p-button-success',
        icon: 'pi pi-sign-in',
        accept: async () => {
            loader.value = true
            console.log(user, user.mobile)
            let payload = {
                number: user.mobile,
                partner: "SPRACHELERNEN",
                secret: "THISTOKENISNOTFRONTENDSAFE"
            }
            axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/partner/login/send-2fa', payload)
            .then(response => {
                toast.add({severity: 'success', summary: 'SMS gesendet', detail: 'Die SMS wurde erfolgreich gesendet'})
                loader.value = false
            })
        }
    })
}

const create_user = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/create-user', new_user.value)
    .then(response => {
        toast.add({severity: 'success', summary: 'Benutzer erstellt', detail: 'Der Benutzer wurde erfolgreich erstellt'})
        new_user_dialog.value = false
        loader.value = false
        get_users()
    })
}

const save_user = () => {
    loader.value = true
    axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/sprachtechnik/coursemaker/save-user', user.value)
    .then(response => {
        toast.add({severity: 'success', summary: 'Benutzer gespeichert', detail: 'Der Benutzer wurde erfolgreich gespeichert'})
        edit_user_slider.value = false
        loader.value = false
        get_users()
    })
}

const edit_user = (userdata) => {
    user.value = userdata
    edit_user_slider.value = true
}

const get_type_name = (type) => {
    let type_name = types.value.find(t => t.value === type)
    return type_name ? type_name.label : 'Unbekannt'
}

</script>
<style lang="scss" scoped>
    @import '@/core/assets/primevue/primeflex.scss';
</style>
<template>
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner v-if="loader" style="width:50px;height:50px" strokeWidth="8" animationDuration="1.5s" aria-label="Custom ProgressSpinner" class="spinner" />
    <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
        <TabView class="col-12" ref="tabview4">
            <TabPanel>
                <template #header>
                    <i class="pi pi-graduation-cap mr-2"></i>
                    <span class="mr-3">Studenten</span>
                    <Badge :value="String(users.length)" severity="info" />
                </template>
                <Toolbar>
                    <template #start>
                        <span class="p-input-icon-left mr-3">
                            <i class="pi pi-search" />
                            <InputText v-model="filter_users['global'].value" placeholder="Suche" style="width: 400px" />
                        </span>
                        <Button label="Neuer Student" icon="pi pi-plus" class="p-button-success" @click="new_user_dialog = true" />
                    </template>
                </Toolbar>
                <div rounded class="overflow-hidden shadow-4 rounded border-200 mt-4">
                    <DataTable v-model:filters="filter_users" :value="users" responsiveLayout="scroll" :rowHover="true" :rows="50" :rowsPerPageOptions="[50,100,200]" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
                        <Column field="id" header="Student">
                            <template #body="slotProps">
                                <Avatar icon="pi pi-graduation-cap" class="mr-2 bg-primary text-white" shape="circle" />
                                <span class="font-bold">{{ slotProps.data.first_name }} {{ slotProps.data.last_name }}</span>
                            </template>
                        </Column>
                        <Column field="first_name" header="Vornamen" hidden />
                        <Column field="last_name" header="Nachnamen" hidden />
                        <Column field="email" header="Email" sortable />
                        <Column field="type" header="Typ">
                            <template #body="slotProps">
                                <Chip :label="get_type_name(slotProps.data.type)" />
                            </template>
                        </Column>
                        <Column field="courses" header="Kurse">
                            <template #body="slotProps">
                                <Avatar :label="String(slotProps.data.courses.length)" icon="pi pi-book" class="p-mr-2" />
                            </template>
                        </Column>
                        <Column field="course_progress" header="Vortschritt" sortable>
                            <template #body="slotProps">
                                <ProgressBar :value="slotProps.data.course_progress"> {{ slotProps.data.course_progress }}% </ProgressBar>
                            </template>
                        </Column>
                        <Column>
                            <template #body="slotProps">
                                <Button icon="pi pi-mobile" class="p-button-rounded p-button-text p-mr-2" @click="send_sms(slotProps.data)" v-tooltip.top="'Zugang über senden'" />
                                <Button icon="pi pi-pencil" class="p-button-rounded p-button-text p-mr-2" @click="edit_user(slotProps.data)" v-tooltip.top="'Student bearbeiten'" />
                                <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-text" @click="delete_user(slotProps.data)" v-tooltip.top="'Student löschen'" />
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-users mr-2"></i>
                    <span class="mr-3">Vertreter</span>
                    <Badge :value="String(0)" severity="info" />
                </template>
            </TabPanel>
            <TabPanel>
                <template #header>
                    <i class="pi pi-building mr-2"></i>
                    <span class="mr-3">Firmen</span>
                    <Badge :value="String(0)" severity="info" />
                </template>
            </TabPanel>
        </TabView>
    </div>
    <!------------------------------------------------------------------->
    <!--------------------------- Dialogs ------------------------------->
    <!------------------------------------------------------------------->
    <Sidebar header="Benutzer bearbeiten" v-model:visible="edit_user_slider" position="left" style="width: 50%">
        <div class="grid">
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Vornamen
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="user.first_name" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Nachnamen
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="user.last_name" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Email
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="user.email" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Mobile
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="user.mobile" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Typ
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Dropdown v-model="user.type" :options="types" optionLabel="label" optionValue="value" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                Kurse
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <MultiSelect filter v-model="user.courses" :options="course_selector" optionLabel="label" optionValue="value" class="w-full" />
            </div>
            <div class="col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <Button label="Benutzer speichern" icon="pi pi-save" class="p-button-success w-auto mr-2" @click="save_user()" />
                <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="edit_user_slider = false" />
            </div>
        </div>
    </Sidebar>
    <Dialog v-model:visible="new_user_dialog" header="Neuer Benutzer" :style="{width: '700px'}" :modal="true" class="p-fluid z-5">
        <div class="grid">
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-items-center">
                Vornamen
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="new_user.first_name" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-items-center">
                Nachnamen
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="new_user.last_name" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-items-center">
                Email
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="new_user.email" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-items-center">
                Mobile
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <InputText v-model="new_user.mobile" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-items-center">
                Typ
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <Dropdown v-model="new_user.type" :options="types" optionLabel="label" optionValue="value" class="w-full" />
            </div>
            <div class="col-2 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0 flex align-items-center">
                Kurse
            </div>
            <div class="col-10 p-2 border-blue-100 border-solid border-bottom-1 m-0">
                <MultiSelect filter v-model="new_user.courses" :options="course_selector" optionLabel="label" optionValue="value" class="w-full" />
            </div>
            <div class="col-12 p-2 font-bold border-blue-100 border-solid border-bottom-1 bg-blue-50 m-0">
                <Button label="Benutzer erstellen" icon="pi pi-save" class="p-button-success w-auto mr-2" @click="create_user()" />
                <Button label="Abbrechen" icon="pi pi-times" class="p-button-danger w-auto" @click="new_user_dialog = false" />
            </div>
        </div>
    </Dialog>
</template>